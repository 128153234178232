<style lang="scss" scoped>
.user-details{
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
</style>

<template>
  <section class="user-details">
    <h1>用户详情</h1>

    <p>id：{{ $route.query.id }}</p>
    <p>姓名：王小虎</p>
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>
